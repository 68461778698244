import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Media,
  Row,
  Table,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import mini card widgets
import MiniCards from "./mini-card"

//Import Images
import profile1 from "assets/images/profile-img.png"

// import charts
import ApexRevenue from "../ApexRevenue"
import { getUserProfile } from "store/actions"
import images from "assets/images"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import projectColumns from "./projectColumns"

class ContactsProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      miniCards: [
        {
          title: "Completed Projects",
          iconClass: "bx-check-circle",
          text: "125",
        },
        { title: "Pending Projects", iconClass: "bx-hourglass", text: "12" },
        { title: "Total Revenue", iconClass: "bx-package", text: "$36,524" },
      ],
    }
  }

  componentDidMount() {
    const { onGetUserProfile } = this.props
    onGetUserProfile()
  }

  render() {
    const { userProfile } = this.props

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Profile</title>
          </MetaTags>
        
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Contacts" breadcrumbItem="Profile" />

            <Row>
              <Col xl="12">
                <Card className="overflow-hidden ">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs="7">
                        <div className="text-primary p-3">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>It will seem like simplified</p>
                        </div>
                      </Col>
                      <Col xs="5" className="align-self-end">
                        <img src={profile1} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col sm="4">
                        <div className="avatar-md profile-user-wid mb-4">
                          <img
                            src={images[userProfile.img]}
                            alt=""
                            className="img-thumbnail rounded-circle"
                          />
                        </div>
                        <h5 className="font-size-15 text-truncate">
                          {userProfile.name}
                        </h5>
                        <p className="text-muted mb-0 text-truncate">
                          {userProfile.designation}
                        </p>
                      </Col>

                      <Col sm={8}>
                        <div className="pt-4">
                          
                          <div className="mt-4">
                            <Link
                              to=""
                              className="btn btn-primary btn-sm"
                            >
                              Upload Image{" "}
                              
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">Personal Information</CardTitle>

                    <p className="text-muted mb-4">
                      {userProfile.personalDetail}
                    </p>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Full Name :</th>
                            <td>{userProfile.name}</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile :</th>
                            <td>{userProfile.phone}</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>{userProfile.email}</td>
                          </tr>
                          <tr>
                            <th scope="row">Location :</th>
                            <td>{userProfile.location}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <p>&nbsp;</p>
                    <button className="btn btn-primary btn-sm">Edit Profile</button>
                  </CardBody>
                </Card>

                <Card>
                  
                </Card>
              </Col>

              
            </Row>
          
        </div>
      </React.Fragment>
    )
  }
}

ContactsProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
})

const mapDispatchToProps = dispatch => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsProfile))
