import React, { Component } from "react"
import MetaTags from 'react-meta-tags';

import {
  Card,
  CardBody,
  CardColumns,
  CardDeck,
  CardFooter,
  CardHeader,
  CardImg,
  CardImgOverlay,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"

// import images
import img1 from "../../assets/images/small/img-1.jpg"
import img2 from "../../assets/images/small/img-2.jpg"
import img3 from "../../assets/images/small/img-3.jpg"
import img4 from "../../assets/images/small/img-4.jpg"
import img5 from "../../assets/images/small/img-5.jpg"
import img6 from "../../assets/images/small/img-6.jpg"
import img7 from "../../assets/images/small/img-7.jpg"
import img11 from "../../assets/images/small/img-11.jpeg"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class Successfullpage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Successfull Message</title>
          </MetaTags>
          
           <Breadcrumbs title="" breadcrumbItem="Successfull Message" />

            <Row>
              
              <Col mg={12} xl={12}>
                <Card className="text-center">
                  <CardImg top className="img-fluid" src={img11} alt="Skote" />
                  <CardBody className="mt-5 mb-4">
                    <CardTitle className="h4 mt-0 text-primary">SUCCESSFUL</CardTitle>
                    <CardText>
                      As Mohamad Noor Shilla . Your Next<br /> Check in time is at 18:00. 

                  </CardText>
                  </CardBody>
                  
                  <CardBody>
                    <Link to="#" className="card-link btn btn-primary d-grid gap-2">
                      0.3km
                    </Link>
                    
                  </CardBody>
                </Card>
              </Col>

              
              
            </Row>

        
            
          
        </div>
      </React.Fragment>
    )
  }
}

export default Successfullpage
