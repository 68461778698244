import React, { Component } from "react"
import MetaTags from 'react-meta-tags';

import {
  Card,
  CardBody,
  CardColumns,
  CardDeck,
  CardFooter,
  CardHeader,
  CardImg,
  CardImgOverlay,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"

// import images
import img1 from "../../assets/images/small/img-1.jpg"
import img2 from "../../assets/images/small/img-2.jpg"
import img3 from "../../assets/images/small/img-3.jpg"
import img4 from "../../assets/images/small/img-4.jpg"
import img5 from "../../assets/images/small/img-5.jpg"
import img6 from "../../assets/images/small/img-6.jpg"
import img7 from "../../assets/images/small/img-7.jpg"
import { Link } from "react-router-dom"
import FormUpload from "./FormUpload"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class securityreport extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Contractor Report</title>
          </MetaTags>
          
            <Breadcrumbs  breadcrumbItem="Contractor Report" />




            <Row>
              



              <Col mg={12} xl={12}>

                <Card>
                <CardBody>
                <label for="exampleInputEmail1" class="form-label">Title</label>
                <input type="email" class="form-control mb-3" id="exampleFormControlInput1" placeholder="Enter Title" />

                <label for="exampleInputEmail1" class="form-label">Select Category</label>
                <select class="form-select mb-3" aria-label="Default select example">
  <option selected>Select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
                    
                   <label for="exampleInputEmail1" class="form-label">Select Level</label> 
                   <select class="form-select mb-3" aria-label="Default select example">
  <option selected> Select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
                
                   <label for="exampleInputEmail1" class="form-label">Select Ares</label> 
                   <select class="form-select mb-3" aria-label="Default select example">
  <option selected>Select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>


<label for="exampleInputEmail1" class="form-label">Problem Found</label>
<input type="email" class="form-control mb-3" id="exampleFormControlInput1" placeholder="Enter Problem" /> 


<label for="exampleInputEmail1" class="form-label">Action taken</label>
<input type="email" class="form-control mb-3" id="exampleFormControlInput1" placeholder="Enter Action taken"  />




</CardBody>
  </Card>

  <Card>
  <CardBody>

   <div class="row">
  
  <div class="col-12">
  <label for="exampleInputEmail1" class="form-label">Add Files</label>
    <input type="file" class="form-control mb-3" id="inputGroupFile01" />

  </div>
  <div class="col d-grid gap-2"><button class="btn btn-primary" type="submit">Add More files</button></div>

</div>

  </CardBody>
  </Card>

 


                <Card>
                
                  

                

                
                  
                  
                  <FormUpload />
                </Card>
              </Col>

      
            </Row>

            
           
        </div>
      </React.Fragment>
    )
  }
}

export default securityreport
