import React, { Component } from "react"
import MetaTags from 'react-meta-tags';

import {
  Card,
  CardBody,
  CardColumns,
  CardDeck,
  CardFooter,
  CardHeader,
  CardImg,
  CardImgOverlay,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"

// import images
import img1 from "../../assets/images/small/img-1.jpg"
import img2 from "../../assets/images/small/img-2.jpg"
import img3 from "../../assets/images/small/img-3.jpg"
import img4 from "../../assets/images/small/img-4.jpg"
import img5 from "../../assets/images/small/img-5.jpg"
import img6 from "../../assets/images/small/img-6.jpg"
import img7 from "../../assets/images/small/img-7.jpg"
import { Link } from "react-router-dom"
import FormUpload from "./FormUpload"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class cleanertask extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Cleaner Task</title>
          </MetaTags>
         
            <Breadcrumbs  breadcrumbItem="Cleaner Task" />




            <Row>
              



              <Col mg={12} xl={12}>

                <Card>
                <CardBody>
                <label for="exampleInputEmail1" class="form-label">Select Tower</label>
                <select class="form-select" aria-label="Default select example">
  <option selected>Select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
                  
                  <label for="exampleInputEmail1" class="form-label mt-3">Select Level</label>
                   <select class="form-select" aria-label="Default select example">
  <option selected>Select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>

<label for="exampleInputEmail1" class="form-label mt-3">Select area</label>
                   <select class="form-select" aria-label="Default select example">
  <option selected>Select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
</CardBody>
  </Card>


                <Card>
                
                  

                

                 
                  
                  
                  <FormUpload />
                </Card>
              </Col>

      
            </Row>

            
           
        </div>
      </React.Fragment>
    )
  }
}

export default cleanertask
